<template>
  <Dialog
      :header="modalTitle"
      v-model:visible="showModal"
      modal
      :style="{ width: modalWidth }"
      @hide="hideModal"
  >
    <div v-for="(field, index) in formFields" :key="index" class="form-group">
      <label :for="field.label">{{ field.label }}</label>

      <!-- Text Input -->
      <InputText
          v-if="field.type === 'text'"
          :value="getNestedValue(formData, field.model)"
          @input="setNestedValue(formData, field.model, $event.target.value)"
          :placeholder="field.placeholder"
          :type="field.inputType || 'text'"
          class="w-100"
      />

      <div v-if="field.type === 'switch'" class="form-check form-switch">
        <input v-if="getNestedValue(formData, field.model)" class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked>
        <input v-else class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
        <label class="form-check-label" for="flexSwitchCheckDefault">Default switch checkbox input {{  getNestedValue(formData, field.model) }}</label>
      </div>


      <!-- Date Input -->
      <InputText
          v-if="field.type === 'date'"
          type="date"
          :value="formatDate(getNestedValue(formData, field.model))"
          @input="setNestedValue(formData, field.model, $event.target.value)"
          :placeholder="field.placeholder"
          class="w-100"
      />


      <!-- Number Input -->
      <InputText
          v-if="field.type === 'number'"
          type="number"
          :value="getNestedValue(formData, field.model)"
          @input="setNestedValue(formData, field.model, $event.target.value)"
          :placeholder="field.placeholder"
          class="w-100"
      />

      <div class="d-flex align-items-center gap-1" v-if="field.type === 'hour-minute' || field.type === 'minute' ">
        <InputText
            type="number"
            :value="getNestedValue(formData, field.hour)"
            @input="setNestedValue(formData, field.hour, $event.target.value)"
            :placeholder="field.placeholder"
            class="w-100"
        />
        :
        <InputText
            type="number"
            :value="getNestedValue(formData, field.minute)"
            @input="setNestedValue(formData, field.minute, $event.target.value)"
            :placeholder="field.placeholder"
            class="w-100"
        />
      </div>



      <!-- MultiSelect -->
      <MultiSelect
          v-if="field.type === 'multiselect'"
          v-model="formData[field.model]"
          :options="field.options"
          :optionLabel="field.optionLabel"
          :filter="field.filter"
          :placeholder="field.placeholder"
          class="w-100"
      />



      <!-- Select -->
      <select class="form-select"
              aria-label="Default select example"
              v-if="field.type === 'select'"
              @change="setNestedValue(formData, field.model, $event.target.value)"
              :value="getNestedValue(formData, field.model)"
              placeholder="Select an option">
        <option value="" disabled>Select an option</option> <!-- Varsayılan seçenek -->
        <option v-for="option in field.options"
                :key="option.value"
                :value="option.value">
          {{ option.text }} <!-- Seçenek metni -->
        </option>
      </select>





    </div>

    <!-- Mesajlar -->
    <Message
        v-for="msg in messages"
        :severity="msg.severity"
        :key="msg.id"
        :life="5000"
        :sticky="false"
    >
      {{ msg.content }}
    </Message>

    <!-- Footer -->
    <div class="modal-footer">
      <button
          type="button"
          class="btn btn-default"
          @click="hideModal"
      >
        Close
      </button>
      <Button
          label="Save"
          icon="pi pi-save"
          iconPos="left"
          @click="saveChanges"
      />
    </div>
  </Dialog>
</template>

<script>
export default {
  props: ['show', 'modalTitle', 'modalWidth', 'formFields','detailEndpoint','totalDataName','saveEndpoint','method','isOnlydata'],
  data() {
    return {
      formData: {},
      messages: [],
    };
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.fetchDetail();
      }
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      },
    },
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    fetchDetail() {
      this.$appAxios.get(this.detailEndpoint)
          .then((response) => {
            console.log("response",response)
            if (this.isOnlydata){
              this.formData = response.data[this.totalDataName];
            }else {
              this.formData = response.data.data[this.totalDataName];
            }
          })
          .catch((err) => {
            console.error('Error fetching details:', err);
          });
    },
    saveChanges() {
      this.$emit('saveEndpoint', this.formData)
    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },

    setNestedValue(obj, path, value) {
      const keys = path.split('.');
      keys.reduce((acc, part, index) => {
        if (index === keys.length - 1) {
          acc[part] = value;
        }
        return acc[part];
      }, obj);
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toISOString().split('T')[0]; // YYYY-MM-DD formatı
    },
  },
};
</script>
