<template>
  <Header/>

  <router-view/>
</template>
<script>
import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
  },
  beforeCreate() {
    this.$store.dispatch('getOccupations');
    this.$store.dispatch('getRelationships');
    this.$store.dispatch('getHouses');
    this.$store.dispatch('getSigns');
    this.$store.dispatch('getCategories');
    this.$store.dispatch('getSupportedLanguages');
  },
}
</script>
<style>
.title {
  font-size: 1.1rem;
  font-weight: 550;
  color: black;
}

.container {
  max-width: 100% !important;
}

hr {
  background-color: lightslategrey;
}

main {
  background: #fafafa;
}

.home-div {
  padding-right: 0px !important;
  padding-top: 100px !important;
}

label {
  font-weight: 600 !important;
}

.form-floating {
  border: 1px solid #a2adb8 !important;
  background-color: #f7f9fa !important;
  border-radius: 0.42rem !important;
}

.my-sweet {
  background: rgba(33, 36, 51, 0.95) !important;
}

.my-sweet .swal2-popup {
  border-radius: 20px !important;
}

.toast-bg {
  background: transparent;
}

.toast-bg .swal2-x-mark-line-left,
.toast-bg .swal2-x-mark-line-right {
  background-color: white !important;
}

.toast-bg .swal2-icon.swal2-error {
  border-color: white !important;
}

.toast-bg .swal2-toast {
  background-color: #fd6a57 !important;
}

.toast-bg .swal2-title {
  color: white !important;
}

select {
  background-color: #f3f6f9;
  color: #3f4254;
  border-radius: 0.42rem;
  width: 100%;
  border: none;
  padding-top: 15px !important;
  padding-left: 18px !important;
  padding-bottom: 15px !important;
}

.swal2-icon.swal2-error {
  color: #fd6a57 !important;
  border-color: #fd6a57 !important;
}

.swal2-icon.swal2-warning {
  border-color: #fd6a57 !important;
  color: #fd6a57 !important;
}

.swal2-html-container {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 1.1rem;
  margin-top: 1.5rem;
  color: #3f4254;
  max-height: 200px;
  overflow: auto;
}

.swal2-confirm {
  background-color: #fd6a57 !important;
  border-color: #fd6a57 !important;
  color: #f7f9fa !important;
  margin: 15px 5px 0;
  padding: 10px 20px 10px 20px;
  box-shadow: none !important;
}

.white-bg {
  background-color: #ffff !important;
}

.page-title {
  font-family: Lora;
  font-size: 26px;
  line-height: 32px;
  color: #2a343d;
}

.help-message {
  margin: 0;
  color: #fd6a57;
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  text-align: center;
  display: block;
}

.mw-none {
  max-width: none;
}

.my-scroll-up {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.658);
  border: rgba(0, 0, 0, 0.658);
  border-radius: 100px;
  bottom: 25px;
  color: white;
  cursor: pointer;
  display: flex;
  height: 55px;
  justify-content: center;
  position: fixed;
  right: 10px;
  width: 55px;
  z-index: 99;
}

@media screen and (max-width: 450px) {
  .my-scroll-up {
    bottom: 10px;
    right: 15px;
    transform: translateX(5px) scale(0.7);
  }
}

.link-face {
  cursor: pointer !important;
  max-width: fit-content;
  margin-top: 0;
  vertical-align: middle;
  border-top: none !important;
  font-family: Open Sans;
  font-size: 15px;
  line-height: 24px;
  color: #f7f9fa;
  outline: none;
  text-decoration: none;
  background-color: transparent;
}

.link-face:hover {
  color: rgb(162, 173, 184);
}

.no-wrap {
  white-space: nowrap;
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

p,
a,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Ubuntu" !important;
}

body {
  font-family: "Ubuntu" !important;
}

html {
  font-size: 14px;
}

.contracts > a {
  color: #71b0bd !important;
}

.contracts > a:hover {
  text-decoration: underline !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span {
  font-family: "Ubuntu", sans-serif !important;
  font-weight: 400;
  letter-spacing: 0;
}

body {
  height: 100% !important;
}

.select2-container--default .select2-selection--single {
  height: 44px !important;
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}

.select2-container--default
.select2-selection--single
.select2-selection__arrow {
  top: 10px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 13px;
  padding-top: 0.65rem !important;
}

/* input:focus::placeholder {
  color: transparent;
} */

.container {
  max-width: 100%;
}

.content {
  padding: 0px;
}

.main-header {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0px;
  font-weight: 600;
  color: #6367b7;
}

.secondary-header {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #3a3d4b;
  font-weight: 400;
}

.third-header {
  font-size: 12px;
  text-align: left;
  letter-spacing: 0px;
  color: #3a3d4b;
  font-weight: 300;
}

@media (max-width: 991.98px) {
  .header-menu-wrapper {
    z-index: 1001;
    position: fixed;
    top: 0;
    bottom: 0;
    -webkit-transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease,
    top 0.3s ease;
    transition: left 0.3s ease, right 0.3s ease, bottom 0.3s ease, top 0.3s ease;
    /*left: -380px !important;*/
    width: 275px;
  }

  .hub21MenuLogo {
    margin-left: 100px !important;
  }
}

@media (min-width: 992px) {
  .mobileMenu {
    display: none;
  }
}

/* input,
select {
  border: 1px solid #6468b2 !important;
  border-radius: 12px !important;
} */

.select2-selection {
  border: 1px solid #6468b2 !important;
  border-radius: 12px !important;
}

.form-control {
  font-size: 13px !important;
}

.form-group {
  margin-bottom: 0.75rem;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(33, 36, 51, 0.95) !important;
}

.swal2-container {
  padding: 36px !important;
}

.swal2-popup {
  border-radius: 20px !important;
}

.swal2-confirm {
  background: #6367b7 !important;
  color: white !important;
  padding: 10px 20px 10px 20px;
}

.menu-item {
  padding: 10px !important;
}

@media (max-width: 1199px) {
  .menuDiv {
    display: none !important;
  }
}

.hp-1 {
  font-size: 13px;
  font-weight: 400;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 1199px) {
  .w-md-50 {
    width: 100% !important;
  }

  .w-md-35 {
    width: 100% !important;
  }

  .main-header {
    text-align: center;
  }

  .secondary-header {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .w-md-50 {
    width: 50% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }
}

.btn:focus {
  box-shadow: none !important;
}

@media (min-width: 1200px) {
  .secondary-header {
    max-width: 400px !important;
  }
}

.form-control:focus {
  box-shadow: none !important;
}

.swal2-html-container {
  font-family: "Ubuntu" !important;
  font-size: 16px !important;
}

.swal2-title {
  font-family: "Ubuntu" !important;
  font-size: 20px !important;
}

a {
  text-decoration: none !important;
}

i {
  font-style: normal !important;
  font-size: 12px;
  color: #3a3d4b;
}

.vc-highlight {
  border-radius: 5px !important;
  background-color: #6367b7 !important;
}

.vc-day-content:hover {
  background-color: #6367b7 !important;
  border-radius: 5px !important;
}

.vc-day-content:focus {
  background-color: #6367b7 !important;
  border-radius: 5px !important;
}

.vc-title {
  font-family: "Ubuntu" !important;
}

.vc-weekday {
  font-family: "Ubuntu" !important;
}

.form-select:focus {
  box-shadow: none !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.grecaptcha-badge {
  display: none !important;
}

.pi {
  font-family: "primeicons" !important;
}

/* Fullcalendar */
/* .fc-button-primary {
  background-color: #6367b7 !important;
  border-color: #6367b7 !important;
}

.fc-next-button.fc-button.fc-button-primary {
  border-left: 1px solid #2a343d !important;
  margin: auto 0 !important;
}

.fc-prev-button.fc-button.fc-button-primary {
  border-right: 1px solid #2a343d !important;
}

.fc-col-header-cell-cushion {
  margin: 10px 0;
}

.fc-event {
  /* -webkit-box-shadow: 0px 3px 14px -1px #000000 !important; */
/* box-shadow: 0px 3px 14px -1px #000000 !important; */
/* } */

.event-workshop {
  border-color: #000000 !important;
  background-color: #57bbc0 !important;
}

.event-workshop > .fc-daygrid-event-dot {
  background-color: #57bbc0 !important;
}

.event-makeup {
  border-color: #000000 !important;
  background-color: #fcc557 !important;
}

.event-makeup > .fc-daygrid-event-dot {
  background-color: #fcc557 !important;
}

.event-single {
  border-color: #000000 !important;
  background-color: #557bbe !important;
}

.event-single > .fc-daygrid-event-dot {
  background-color: #557bbe !important;
}

.event-group {
  border-color: #000000 !important;
  background-color: #e76463 !important;
}

.event-group > .fc-daygrid-event-dot {
  background-color: #e76463 !important;
}

#fc-dom-1 {
  display: inline-block;
  margin: auto 30px;
}

#calendar .fc-agendaWeek-view .fc-today,
#calendar .fc-agendaDay-view .fc-today {
  background: red !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 1rem !important;
}

span {
  font-size: 14px;
}

.custom-label {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.custom-label-success {
  background: #c8e6c9;
  color: #256029;
}

.custom-label-danger {
  background: #ffcdd2;
  color: #c63737;
}

.custom-label-info {
  background: #eccfff;
  color: #694382;
}

.custom-label-primary {
  background: #a1e0ed;
  color: #557bbe;
}

.custom-label-warning {
  color: #856404;
  background-color: #fff3cd;
}

.p-column-title {
  font-weight: bold !important;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 52px;
  background-color: red;
  border-radius: 30px;
  border: 2px solid gray;
}

.toggle:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: gray;
  top: 1px;
  left: 1px;
  transition: all 0.5s;
}

.checkbox:checked + .toggle::after {
  left: 49px;
}

.checkbox:checked + .toggle {
  background-color: green;
}

.checkbox {
  display: none;
}
</style>
<style scoped>
:global(.p-scrollpanel.custombar .p-scrollpanel-wrapper) {
  border-right: 10px solid rgba(250, 250, 250, 0.8);
}

:global(.p-scrollpanel.custombar .p-scrollpanel-bar) {
  background-color: rgb(105, 88, 214);
  opacity: 1;
  transition: background-color 0.3s;
}

:global(.p-scrollpanel.custombar .p-scrollpanel-bar:hover) {
  background-color: var(--primary-400);
}
</style>
