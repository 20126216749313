import axios from "axios";
import store from "@/store"
export const appAxios = axios.create({
    baseURL:process.env.VUE_APP_API_BASE_URL,
})
appAxios.interceptors.request.use(config => {

    config.headers["Accept"] = "application/json";
    config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");

    return config;
});

appAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error)
        if(error?.response?.status == 401){
            store.dispatch("logout")
        }
        let errMessage = "";
        if (error?.response?.data?.errors){
            Object.entries(error.response.data.errors).forEach(item => {
                errMessage += item[1] + "<br>"
            })
        }
        // console.clear()
        if(errMessage === "Unauthorized.<br>" && localStorage.getItem("token")){
            store.dispatch("logout")
        }
        return Promise.reject(error);
    }
)