<template>
  <Dialog
      :header="modalTitle"
      v-model:visible="showModal"
      modal
      :style="{ width: modalWidth }"
      @hide="hideModal">
    <form @submit.prevent="submitForm">
      <div v-for="(field, index) in formFields" :key="index" class="form-group">
        <label :for="field.model">{{ field.label }}</label>

        <!-- Text input -->
        <input v-if="field.type === 'text'"
               :id="field.model"
               :placeholder="field.placeholder"
               v-model="formData[field.model]"
               type="text"
               class="form-control" />

        <!-- Number input -->
        <input v-else-if="field.type === 'number'"
               :id="field.model"
               :placeholder="field.placeholder"
               v-model="formData[field.model]"
               type="number"
               class="form-control" />

        <!-- Date input -->
        <input v-else-if="field.type === 'date'"
               :id="field.model"
               :placeholder="field.placeholder"
               v-model="formData[field.model]"
               type="date"
               class="form-control" />

        <!-- Select input -->
        <select v-else-if="field.type === 'select'"
                :id="field.model"
                v-model="formData[field.model]"
                class="form-control">
          <option v-for="option in field.options" :key="option.value" :value="option.value">
            {{ option.text }}
          </option>
        </select>

        <!-- Switch input -->
        <input v-else-if="field.type === 'switch'"
               :id="field.model"
               type="checkbox"
               v-model="formData[field.model]"
               class="form-switch" />

        <!-- Custom hour-minute input -->
        <div v-else-if="field.type === 'hour-minute'" class="hour-minute-input">
          <input type="number" v-model="formData[field.hour]" placeholder="Hour" />
          <input type="number" v-model="formData[field.minute]" placeholder="Minute" />
        </div>

        <!-- Custom field types can be added here -->

      </div>

      <!-- Submit button -->
      <button type="submit" class="btn btn-primary">Save</button>
    </form>
  </Dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    modalTitle: String,
    modalWidth: {
      type: String,
      default: '50%'
    },
    formFields: {
      type: Array,
      required: true
    },
    method: {
      type: String,
      default: 'post'
    }
  },
  data() {
    return {
      formData: {}
    };
  },
  watch: {
    formFields: {
      immediate: true,
      handler(fields) {
        fields.forEach(field => {
          if (field.type === 'hour-minute') {
            this.formData[field.hour] = 0;
            this.formData[field.minute] = 0;
          } else {
            this.formData[field.model] = field.type === 'switch' ? false : '';
          }
        });
      }
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      },
    },
  },
  methods: {
    hideModal() {
      this.$emit('hide');
    },
    async submitForm() {
      this.$emit('saveEndpoint', this.formData)
    }
  }
};
</script>
