<template>
  <div class="col-12 data-table-div new-version-table">
    <div class="col-12 d-flex gap-3 table-buttons justify-content-end">
      <div class="d-flex gap-3">
        <div
            v-if="search"
            class="pe-md-3 d-flex align-items-center"
            :class="this.$store.state.isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
          <div class="input-group">
            <span style="border: 1px solid #6366F1;" class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
                style="border: 1px solid rgb(99, 102, 241) !important; !important;border-left: transparent !important;"
                type="text"
                class="form-control"
                :placeholder="
                this.$store.state.isRTL ? 'أكتب هنا...' : 'Type here...'
              "
                v-model="searchQuery"
                @input="fetchData"
            />
          </div>
        </div>
<!--        <div v-if="addButton">
          <button @click="$emit('add-button')" class="p-button-primary p-button p-button-outlined d-flex justify-content-evenly" style="width: 10vw;font-size: 12px">
            <span>Add</span>
          </button>
        </div>-->
        <div v-if="blogDropdown" class="select-div-for-icon d-flex gap-3">
          <select
              id="choices-questions"
              ref="choicesQuestions2"
              class="form-control p-button-primary p-button p-button-outlined d-flex justify-content-evenly"
              style="width: max-content; font-size: 12px"
              name="choices-questions"
              v-model="selectedLangueageBlog"
          >
            <option value="en">En</option>
            <option value="tr">Tr</option>
            <option value="es">Es</option>
          </select>
          <select
              id="choices-questions"
              ref="choicesQuestions"
              class="form-control p-button-primary p-button p-button-outlined d-flex justify-content-evenly"
              style="width: max-content; font-size: 12px"
              name="choices-questions"
              @change="getBlogs(this.$refs.choicesQuestions.value)"
              v-model="selectedBlog"
          >
            <option value="all">All</option>
            <option value="web">Web</option>
            <option value="app">App</option>
            <option value="celestia">Celestia</option>
          </select>
        </div>
        <div v-if="celestiaDropdown" class="select-div-for-icon d-flex gap-3">
          <select
              id="choices-questions"
              ref="choicesQuestions2"
              class="form-control p-button-primary p-button p-button-outlined d-flex justify-content-evenly"
              style="width: max-content; font-size: 12px"
              name="choices-questions"
              @change="getCelestiaBlogs()"
              v-model="selectedCelestiaLangueageBlog"
          >
            <option value="en">En</option>
            <option value="tr">Tr</option>
            <option value="es">Es</option>
          </select>
        </div>
        <div v-if="categoryDropdown" class="select-div-for-icon">
          <select
              id="choices-questions"
              ref="choicesQuestions"
              class="form-control p-button-primary p-button p-button-outlined d-flex justify-content-evenly"
              style="width: max-content; font-size: 12px"
              name="choices-questions"
              @change="getCategories(this.$refs.choicesQuestions.value)"
              v-model="selectedCategory"
          >
            <option value="all">All</option>
            <option value="web">Web</option>
            <option value="app">App</option>
          </select>
        </div>
      </div>
      <!--      <MultiSelect v-model="frozenColumns" :options="selectColumns" optionLabel="header"-->
      <!--                   class="border-primary text-primary" filter>-->
      <!--        <template #value="slotProps">-->
      <!--          <i class="fa-regular fa-unlock"></i>-->
      <!--        </template>-->
      <!--      </MultiSelect>-->
      <!--      <MultiSelect v-model="selectColumns" :options="columns" optionLabel="header"-->
      <!--                   class="border-primary text-primary " filter>-->
      <!--        <template #value="slotProps">-->
      <!--          <i class="fa-light fa-grid-2"></i>-->
      <!--        </template>-->
      <!--      </MultiSelect>-->
      <!--      <button class="p-button-primary p-button p-button-outlined"-->
      <!--              @click="clearFilter()">-->
      <!--        <i class="fa-solid fa-filter-slash"></i>-->
      <!--      </button>-->
    </div>
    <DataTable class="p-datatable-striped"
               :rowsPerPageOptions="[10,20,50,100]" :value="apiData" :lazy="true"
               :paginator="totalData > 10" :rows="10" :rowHover="true" :totalRecords="totalData" :loading="loadingTable"
               @page="onPage($event)" scroll-direction="both" :scrollable="true" :reorderableColumns="true" :resizableColumns="true"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :currentPageReportTemplate="'Showing '+lazyParams.first+' to '+(lazyParams.first+apiData.length)+' of '+ totalData +' rows'">

      <!--            columnResizeMode="expand"   v-model:filters="filters" :filters="filters" -->
      <!--               @sort="onSort($event)" @filter="onFilter($event)" filterDisplay="menu"-->
      <template #header>
        <div class="d-flex justify-content-between">
          <div v-if="addButton">
            <Button type="button" icon="pi pi-plus" label="Add Content" class="p-button-info d-inline me-2"
                    @click="$emit('add-button')"/>
          </div>
        </div>
      </template>
      <template v-for="(column) in selectedColumns" :key="column">
        <!--      tablodaki normal sütunlar-->
        <Column :field="column.field"
                :header="column.header"
                :style="[!column.selected ? 'display:none !important' : 'display:table-cell !important']"
                :dataType="'text'" class="orphex-td" style="font-size:14px"
        >
          <!--     :filterField="column.field"     :sortable="column.functionType !== 'detail'" :frozen="column?.frozen"-->
          <!--          Gelen veri listeleme. -->
          <template #body="slotProps">
            <ScrollPanel  style="width: 100%; height: 100px" class="custombar">
            <span v-if="column.functionType === 'none'">
              {{ slotProps.data[column.field] }}
            </span>
            <span v-if="column.functionType === 'res'">
              {{ getNestedValue(slotProps.data, column.field) }}
            </span>
              <span v-else-if="column.functionType === 'Date'">
              {{ formatDate(slotProps.data[column.field]) }}
            </span>
              <span v-else-if="column.functionType === 'bool'">
                <div v-if="!slotProps.data[column.field]" style="background: #c61010;">
                  <span  style="color: white; padding:10px 5px">False</span>
                </div>
                <div v-else style="background: #59c610;">
                  <span  style="color: white; padding:10px 5px">True</span>
                </div>
            </span>
              <template  v-else-if="column.functionType === 'scroll'">
                <ScrollPanel style="width: 100%; height: 100px" class="custombar">
                  <span class="m-0">{{ slotProps.data[column.field] }}</span>
                </ScrollPanel>
              </template>
              <span v-else-if="column.functionType === 'checkArray'">
                <i v-if="slotProps.data[column.field].length < 1 " class="fa-sharp fa-solid fa-circle-xmark"
                   style="color: #d41a0c;"></i>
                <i v-else class="fa-sharp fa-solid fa-circle-check" style="color: #59c610;"></i>
            </span>
              <span v-else-if="column.functionType === 'detail'">
                <button @click="$emit('detail-see', slotProps.data)"
                        style="padding: 0.5rem 1rem !important;width: max-content !important;font-size: .8rem;"
                        id="btn-white" class="btn sm-btn w-100 px-3 mb-2 bg-gradient-success"> See Detail
                </button>
            </span>
              <span v-else-if="column.functionType === 'crud'">
                <Button v-if="column.edit" icon="pi pi-pencil" class="p-button-warning p-button-sm me-2 my-2"
                        @click="$emit('detail-see', slotProps.data)" />
                <Button v-if="column.delete" icon="pi pi-trash" class="p-button-danger p-button-sm me-2 my-2"
                        @click="$emit('delet-see', slotProps.data)" />
            </span>
              <span v-else-if="column.functionType === 'delete'">

                <span @click="deleteActiveModal=true"
                      style="padding: 0.5rem 1rem !important;width: max-content !important;font-size: .8rem;"
                      id="btn-white" class="btn sm-btn w-100 px-3 mb-2 bg-gradient-success"> Delete
                </span>
            </span>
            </ScrollPanel>
          </template>
          <template v-if="column.functionType !== 'detail'" #filter="{filterModel}">
            <InputText :type="'text'" v-model="filterModel.value" class="p-column-filter"
                       :placeholder="'Search by '+column.header"/>
          </template>
        </Column>
      </template>
    </DataTable>

  </div>

  <TranslationsModal
      :show="showEditLanguageModal"
      @hide="showEditLanguageModal = false"
      :contentId="editTranslationContentId"
  />
</template>
<script>
import {FilterMatchMode, FilterOperator} from "primevue/api";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from 'primevue/column';
import TranslationsModal from "../../views/Modals/TranslationsModal.vue";

export default {
  emits:["detail-see","detail2-see","add-button","delet-see"],
  props: ["defaultPostData", "axiosDataName", "url", "columns", "sortField","totalDataName", "isOnlydata","blogDropdown","categoryDropdown","addButton", "celestiaDropdown", "search", "dataPagination"],
  data() {
    return {
      loadingTable: true,
      columnSelectCheck: false,
      selectedBlog: 'all',
      selectedLangueageBlog: 'en',
      selectedCelestiaLangueageBlog: 'en',
      selectedCategory: 'all',
      apiData: [],
      totalData: null,

      selectedColumns: [],
      selectColumns: [],
      frozenColumns: [],
      filters: {},
      lazyParams: {
        first: 0,
        rows: 10,
        sortField: '',
        sortOrder: -1,
        filters: [],
      },
      searchQuery: '',
    }
  },
  components: {
    TranslationsModal,
    DataTable,
    InputText,
    Column
  },
  watch: {
    defaultPostData(newValue, oldValue) {
      if (oldValue && newValue) {
        this.getData()
      }
    },
    selectColumns() {
      this.selectedColumns.forEach(c => {
        c.selected = !!this.selectColumns.find(col => col.field === c.field);
      })
      this.defineColumnHeight()
    },
    frozenColumns() {
      this.selectedColumns.forEach(c => {
        c.frozen = !!this.frozenColumns.find(col => col.field === c.field);
      })
    },
    selectedLangueageBlog(){
      this.getBlogs(this.selectedBlog);
    }
  },
  async created() {
    if (!this.sortField) {
      //Eğer bir sıralama metriği yoksa sortorder 0 olmalı
      this.lazyParams.sortOrder = 0
    }
    await this.lazyParamsFunc(0, this.lazyParams.rows, this.sortField, this.lazyParams.sortOrder, this.filters);
    this.selectColumns = this.columns.filter(c => c.selected)
    this.frozenColumns = this.columns.filter(c => c.frozen)
    this.selectedColumns = this.columns
    await this.defineFilters()
    this.getData()
  },
  mounted() {
    this.getBlogs(this.selectedBlog);
    this.getCelestiaBlogs();
    this.getCategories(this.selectedCategory);
  },
  methods: {
    defineFilters() {
      this.filters = {}
      //Her sütunu filtrelenbilir şekilde filtrelere ekliyoruz
      this.columns.forEach(filter => {
        this.filters[filter.field] = {
          operator: FilterOperator.OR,
          constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}]
        }
      })
    },
    fetchData() {
      const postData = {
        id: '',
        first: this.lazyParams.first,
        rows: this.lazyParams.rows,
        sortField: 'createdAt',
        page: 1,
        contentType: ''
      };

      let postObj = this.defaultPostData +
          '&offset=' + this.lazyParams.first +
          '&limit=' + this.lazyParams.rows

      this.$appAxios.post(this.url + postObj, postData)
          .then(res => {
            this.processData(res.data);
          })
          .catch(error => {
            console.error(error);
          });
    },
    getData() {
      this.loadingTable = true

      if (this.dataPagination){
        this.$appAxios.get(this.url,{
          headers: {
            'Content-Type': 'application/json'
          }
        })
            .then(res => {
              this.processData(res.data.data)
              this.loadingTable = false
            })
        this.loadingTable = false
        return
      }
      let postObj = this.defaultPostData +
          '&offset=' + this.lazyParams.first +
          '&limit=' + this.lazyParams.rows
      this.$appAxios.get(this.url + postObj)
          .then(res => {
            this.loadingTable = false
            this.processData(res.data)
            this.loadingTable = false

          })
    },
    getBlogs(platform){
      let postObj = '&offset=' + this.lazyParams.first +
          '&limit=' + this.lazyParams.rows +
          '&filters=' + JSON.stringify(this.lazyParams.filters)
      if (this.lazyParams.sortOrder === 1) {
        postObj += '&sortOrder=' + 'asc'
        postObj += '&sortField=' + this.lazyParams.sortField
      } else if (this.lazyParams.sortOrder === -1) {
        postObj += '&sortOrder=' + 'desc'
        postObj += '&sortField=' + this.lazyParams.sortField
      }

      if(this.blogDropdown){
        this.$appAxios.get(`/admin/blog/v3/list/${this.selectedLangueageBlog}?type=${platform}` + postObj)
            .then(res => {
              this.processData(res.data)
            })
      }
    },
    getCelestiaBlogs(){
      if(this.celestiaDropdown){
        this.$appAxios.get(`/admin/blog/v3/list?type=celestia`)
            .then(res => {
              this.processData(res.data)
            })
      }
    },
    getCategories(platform){
      if(this.categoryDropdown){
        this.$appAxios.get(`/admin/blog/categories?platform=${platform}`)
            .then(res => {
              this.processData(res.data)
            })
      }
    },
    async processData(data) {
      if (this.isOnlydata){
        this.apiData = data.data
        this.totalData = data[this.totalDataName];
      }
      else {
        this.apiData = [...data[this.axiosDataName]];
        this.totalData = data[this.totalDataName];
      }
      this.loadingTable = false
      this.defineColumnHeight()
    },
    defineColumnHeight() {
      const columns = document.querySelectorAll(".orphex-td");
      let maxHeight = 0;

      columns.forEach(column => {
        const columnHeight = column.clientHeight;
        if (columnHeight > maxHeight) {
          maxHeight = columnHeight;
        }
      });
      columns.forEach(column => {
        column.style.height = maxHeight + 'px'
      });
    },

    clearFilter() {
      this.defineFilters();
      this.lazyParamsFunc(0, 10, this.lazyParams.sortField, this.lazyParams.sortOrder, this.filters);
      this.getData();
    },
    onPage(event) {
      this.lazyParamsFunc(event.first, event.rows, this.lazyParams.sortField, this.lazyParams.sortOrder, event.filters);
      this.getData();
    },
    onSort(event) {
      event.sortOrder = event.sortOrder === 1 ? -1 : 1;
      this.lazyParamsFunc(event.first, event.rows, event.sortField, event.sortOrder, event.filters);
      this.getData();
    },
    onFilter(event) {
      this.lazyParamsFunc(event.first, event.rows, this.lazyParams.sortField, this.lazyParams.sortOrder, this.filters);
      this.getData();
    },
    lazyParamsFunc(first, rows, sortField, sortOrder) {
      const lazyFilters = [];
      const filters = this.filters;
      for (const field in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, field)) {
          const filter = filters[field];
          const {constraints, operator} = filter;

          for (const element of constraints) {
            if (element.value) {
              const filterObject = {
                field,
                value: element.value,
                matchMode: element.matchMode
              };

              if (operator !== 'and' || constraints.length === 1) {
                lazyFilters.push(filterObject);
              } else {
                lazyFilters.push([filterObject]);
              }
            }
          }
        }
      }

      this.lazyParams = {
        first: first,
        rows: rows,
        sortField: sortField,
        sortOrder: sortOrder || null,
        filters: lazyFilters
      }

    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    },
    formatDate(data) {
      let date = new Date(data)
      const formatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
      return formatter.format(date);
    },
  },
}
</script>

<style>

.p-datatable-resizable-table > .p-datatable-tbody > tr > td{
  white-space: normal !important;
}
</style>