import dayjs from "dayjs";

export default {
  methods: {
    formatDate(dateString) {
      const date = dayjs(dateString);
      return date.format("DD.MM.YYYY");
    },
    toUTC(date){
      return dayjs(date).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    },
    toastMsg(icon, msg) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: "top",
        customClass: {
          container: "toast-bg",
        },
        showConfirmButton: false,
        timer: 3000,
        showClass: {
          popup: "",
        },
        hideClass: {
          popup: "",
        },
        timerProgressBar: false,
      });
      Toast.fire({
        icon: icon,
        title: msg,
      });
    },
    goPage(pagename) {
      this.$router.push(pagename)
    },
    getParameterByName(name, url = window.location.href) {
      name = name = name.replace(/[[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    isEmpty(val) {
      return (val === undefined || val == null || val.length <= 0) ? true : false;
    },
    showGlobalErrorMessage(message){
      this.$swal.fire({
        text: message,
        icon: "error",
        customClass: {
          container: "my-sweet",
        },
        confirmButtonText: this.$t("global.ok"),
      });
    },
    includesAll(arr, target) {
      return target.every(v => arr.includes(v));
    },
    openUrl(url){
      window.open(url, '_blank').focus();
    },
  }
};
