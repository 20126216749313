import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/welcome",
    name: "Welcome",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Welcome.vue"),
  },
  {
    path: "/app-language-settings",
    name: "AppLanguageSettings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AppLanguageSettings/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chakra-list",
    name: "Chakra",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Chakra/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/dailyContent",
    name: "DailyContent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contents/DailyContent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/weeklyContent",
    name: "WeeklyContent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contents/WeeklyContent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/monthlyContent",
    name: "MonthlyContent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contents/MonthlyContent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/natalContent",
    name: "natalContent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contents/NatalContent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/aspectContent",
    name: "aspectContent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contents/AspectContent.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/astrologs",
    name: "astrologs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Astrologs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contentLookUp",
    name: "contentLookUp",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContentLookUp.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/testAstrologyAPI",
    name: "testAstrologyAPI",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TestAstrologyAPI.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/do-dont",
    name: "doDonts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contents/DoDonts.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  { 
    path: "/discover/weekly",
    name: "discoverWeekly",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Discover/Weekly.vue")
  },
  { 
    path: "/discover/affirmations",
    name: "discoverAffirmations",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Discover/Affirmations.vue")
  },
  { 
    path: "/discover/cards",
    name: "discoverCards",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Discover/Cards.vue")
  },
  { 
    path: "/discover/suggestions",
    name: "discoverSuggestions",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Discover/Suggestions.vue")
  },
  { 
    path: "/dailySuggestion",
    name: "dailySuggestion",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents/DailySuggestion.vue")
  },
  { 
    path: "/verify-content",
    name: "verifyContent",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/VerifyContent.vue")
  },
  { 
    path: "/users",
    name: "users",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Users.vue")
  },
  { 
    path: "/questions",
    name: "Questions",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/QuestionsPage.vue")
  },
  { 
    path: "/biorhythm",
    name: "Biorhythm",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents/Biorhythm.vue")
  },
  { 
    path: "/public-figures",
    name: "Public Figures",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/PublicFigures.vue")
  },
  {
    path: "/users/public-figures",
    name: "Public Figures",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Users/PublicFigures.vue")
  },
  { 
    path: "/celestial-cards",
    name: "Celestial Cards",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents/CelestialCards.vue")
  },
  { 
    path: "/cosmic-events",
    name: "Cosmic Events",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents/CosmicEvents.vue")
  },
  { 
    path: "/images",
    name: "Images",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents/Images.vue")
  },
  { 
    path: "/stories",
    name: "Stories",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents/Stories.vue")
  },
  { 
    path: "/calendar",
    name: "Calendar",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents/Calendar.vue")
  },
  { 
    path: "/yearly",
    name: "Yearly",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/Contents/YearlyContent.vue")
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!localStorage.getItem("token")) {
      next("welcome");
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

export default router;
