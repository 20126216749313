<template>
  <Dialog
      :header="modalTitle"
      v-model:visible="showModal"
  modal
  :style="{'width':'40%'}"
  @hide="hideModal"
  >
  <div class="row mb-3 mt-3">
    <div class="row d-flex justify-content-between">
      <div class="col-md-12 form-group">
        <label class="d-block">{{ deleteMessage }}</label>
      </div>
    </div>
  </div>
  <Message v-for="msg in messages" :severity="msg.severity" :key="msg.id" :life="5000" :sticky="false">
    {{ msg.content }}
  </Message>
  <div class="modal-footer">
    <button
        type="button"
        class="btn btn-default"
        @click="hideModal"
    >
      Close
    </button>
    <Button
        :label="confirmButtonText"
        @click="confirmDelete"
    />
  </div>
  </Dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    itemId: {
      type: Number,
      required: true
    },
    modalTitle: {
      type: String,
      default: 'Delete Item'
    },
    deleteMessage: {
      type: String,
      default: 'Are you sure you want to delete this item?'
    },
    confirmButtonText: {
      type: String,
      default: 'Yes'
    },
    deleteEndpoint: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      messages: []
    }
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      },
    },
  },
  methods: {
    hideModal() {
      this.$emit('update:isVisible', false); // Modalı kapatma
    },
    confirmDelete() {
      this.$appAxios.delete(this.deleteEndpoint)
          .then(() => {
            this.$toast.add({severity: 'success', summary: 'Success', detail: 'Item deleted successfully', life: 3000});
            this.$emit('delete-success'); // Başarılı silme sonrası event
            this.hideModal();
          })
          .catch(() => {
            this.$toast.add({severity: 'error', summary: 'Error', detail: 'Failed to delete item', life: 3000});
            this.hideModal();
          });
    }
  }
}
</script>
