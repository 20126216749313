<template>
  <Dialog
      header="Edit Translations"
      v-model:visible="showModal"
      modal
      :style="{'width':'40%'}"
      @show="onOpen"
  >
    <div class="flex">
      <label class="d-block">Languages:</label>
      <Dropdown
          v-model="selectedLanguage"
          :options="supportedLanguages"
          optionLabel="name"
          optionValue="id"
          placeholder="Select a Language"
          class="w-50 me-3"
      />
      <Button
          v-tooltip="'Add New Language'"
          icon="pi pi-plus"
          iconPos="left"
          class="p-button-success"
          @click="addNewLanguage()"
      />
    </div>
    <TabView>
      <TabPanel v-for="(translation, index) in translations" :key="index"
                :header="getLanguageName(translation.language)">
        <div class="row mb-3 mt-3">
          <div class="col-12">
            <label class="d-block">Verified</label>
            <InputSwitch v-model="translation.isVerified"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Title:</label>
            <InputText class="w-100" type="text" v-model="translation.title"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Text:</label>
            <Textarea class="w-100" type="text" rows="8" v-model="translation.text"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Secondary Title:</label>
            <InputText class="w-100" type="text" v-model="translation.secondaryTitle"/>
          </div>
          <div class="col-md-12 form-group">
            <label class="d-block">Secondary Text:</label>
            <Textarea class="w-100" type="text" rows="8" v-model="translation.secondaryText"/>
          </div>
          <div class="col-12 mt-3 mb-3">
            <label class="d-block">Audio Url:</label>
            <InputText class="w-50 me-3" type="text" disabled v-model="translation.audioUrl"/>
            <Button
                v-tooltip="'Generate audio with the content text'"
                icon="pi pi-plus"
                iconPos="left"
                class="p-button-info me-2"
                @click="generateAudio(translation)"
                :loading="generateAudioLoading"
            />
            <Button
                v-tooltip="'Listen'"
                icon="pi pi-play"
                iconPos="left"
                class="p-button-warning"
                @click="openUrl(translation.audioUrl)"
            />
            <Button
                v-tooltip="'Remove Audio'"
                icon="pi pi-trash"
                class="p-button-danger ms-2"
                @click="removeAudio(translation)"
            />
          </div>
          <div class="mt-4 d-flex">
            <div class="col-8">
              <label class="d-block">Photo Url</label>
              <InputText class="w-50 me-3" type="text" v-model="translation.photoUrl"/>
            </div>
            <div class="col-4">
              <img v-show="translation.photoUrl" :src="translation.photoUrl" alt="Photo" class="w-100"/>
            </div>
          </div>
        </div>
      </TabPanel>
    </TabView>
    <div class="modal-footer">
      <Button label="Cancel" icon="pi pi-times" class="me-2" iconPos="left" @click="hideModal()"/>
      <Button label="Save All" icon="pi pi-save" iconPos="left" @click="editLanguage()"/>
    </div>
  </Dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    contentId: {
      type: Number,
      required: true
    },
    isSubContent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      messages: [],
      translations: [],
      generateAudioLoading: false,
      selectedLanguage: null
    }
  },
  computed: {
    ...mapGetters(['supportedLanguages']),
    showModal: {
      get() {
        return this.show;
      },
      set() {
        this.hideModal();
      }
    },
  },
  methods: {
    onOpen() {
      this.selectedLanguage = null;
      this.fetchTranslations();
    },
    hideModal() {
      this.$emit('hide');
    },
    fetchTranslations() {
      let url = this.isSubContent ? 'admin/content/sub-content/translations/' : 'admin/content/content-translations/';
      this.$appAxios.get(url + this.contentId)
          .then(response => {
            this.translations = response.data;
          });
    },
    getLanguageName(language) {
      return this.supportedLanguages.find(lang => lang.id === language)?.name || language;
    },
    editLanguage() {
      this.translations.forEach(translation => {
        this.$appAxios.put("admin/content/translation",
            {
              id: translation.id,
              title: translation.title,
              text: translation.text,
              secondaryText: translation.secondaryText,
              secondaryTitle: translation.secondaryTitle,
              language: translation.language,
              isVerified: translation.isVerified,
              photoUrl: translation.photoUrl
            }
        )
            .then(() => {
            })
            .catch(() => {
              this.hideModal();
              this.$toast.add({severity: 'error', summary: 'Error', detail: 'Language info not updated', life: 3000});
            })
      });
      this.hideModal();
      this.$toast.add({severity: 'success', summary: 'Success', detail: 'Language info updated', life: 3000});
    },
    generateAudio(translation) {
      this.generateAudioLoading = true;

      this.$appAxios.put("admin/content/translation",
          {
            id: translation.id,
            title: translation.title,
            text: translation.text,
            language: translation.language,
            isVerified: translation.isVerified,
          }
      ).then(() => {
        this.$appAxios.get("admin/content/generate-audio/" + translation.id)
            .then(resp => {
              translation.audioUrl = resp.data;
              this.generateAudioLoading = false;
              this.$toast.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Audio is being generated, you will receive an email when it is ready.',
                life: 3000
              });
            })
            .catch(err => {
              console.log(err)
              this.generateAudioLoading = false;
            });
      })
          .catch(() => {
          });
    },
    removeAudio(translation) {
      this.$appAxios.delete("admin/content/remove-audio/" + translation.id)
          .then(() => {
            translation.audioUrl = "";
          })
          .catch(err => {
            console.log(err)
          });
    },
    addNewLanguage() {
      if (this.selectedLanguage) {
        this.$appAxios.post("admin/content/translation",
            {
              contentId: !this.isSubContent ? this.contentId : null,
              language: this.selectedLanguage,
              subContentId: this.isSubContent ? this.contentId : null
            }
        )
            .then(() => {
              this.fetchTranslations();
            })
            .catch((e) => {
              this.$toast.add({severity: 'error', summary: 'Error', detail: e.response.data, life: 3000});
            });
      }
    },
  }
}
</script>

<style>
</style>